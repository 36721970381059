import HTTP from "./http";

function API() {}
API.prototype = {
  // 登录
  Login: function(data) {
    return HTTP.postJson("/login/login", data);
  },
  // 获取短信验证码
  getSmsCode: function(data) {
    return HTTP.postJson("/login/sms", data);
  },
  // 获取oss token 接口
  getStsToken: function(data) {
    return HTTP.postJson("/oss/token", data);
  },
  // 新增腕表
  newlots: function(data) {
    return HTTP.postJson("/wristWatch/add", data);
  },
  // 删除腕表
  deletelots: function(data) {
    return HTTP.postJson("/wristWatch/delete", data);
  },
  // 编辑腕表
  editorwatch: function(data) {
    return HTTP.postJson("/wristWatch/editor", data);
  },

  // 修改密码
  changePass: function(data) {
    return HTTP.postJson("/login/changePassword", data);
  },
  // 新增用户
  addUser: function(data) {
    return HTTP.postJson("/admin/add", data);
  },
  // 编辑用户
  editorUser: function(data) {
    return HTTP.postJson("/admin/editor", data);
  },
  //根据userId查询用户
  userById: function(data) {
    return HTTP.postJson("/admin/byId", data);
  },
  // 分页查询用户
  getUserList: function(data) {
    return HTTP.postJson("/user/page", data);
  },
  // 用户导出
  userExport: function(data) {
    return HTTP.postJson("/user/exportExcel", data);
  },
  // 分页查询账号
  getAccountList: function(data) {
    return HTTP.postJson("/admin/page", data);
  },
  // 分页查询腕表登记信息
  getWatchRegisterList: function(data) {
    return HTTP.postJson("/watch-register/page", data);
  },
  // 更新腕表验证状态
  UpdateApproveStatus: function(data) {
    return HTTP.postJson("/watch-register/update/approveStatus", data);
  },
  // 解除腕表绑定状态
  RemoveBindingStatus: function(data) {
    return HTTP.postJson("/watch-register/remove/bindingStatus", data);
  },
  // 更新腕表激活状态
  updateActivityStatus: function(data) {
    return HTTP.postJson("/watch-register/update/activeStatus", data);
  },
  // 分页查询腕表主数据
  getWatchMainDataList: function(data) {
    return HTTP.postJson("/wristWatch/byPage", data);
  },

  getWatchById: function(data) {
    return HTTP.postJson("/wristWatch/byId", data);
  },

  // 分页查询腕表主数据
  getWatchBox: function(data) {
    return HTTP.postJson("/user/watch-page", data);
  },
  // 用户状态修改
  changeUserStatus: function(data) {
    return HTTP.postJson("/admin/updateUserStatus", data);
  },
  // 删除用户
  deleteUser: function(data) {
    return HTTP.postJson("/admin/delete", data);
  },
  // 导入数据
  importItems: function(data) {
    return HTTP.uploadFile("/wristWatch/upload/excel", data);
  },
  // 导入数据
  importItemsDrink: function(data) {
    return HTTP.uploadFile("/wristWatch/upload/excel", data);
  },
  // 检验图片是否重复
  duplicateRemoval: function(data) {
    return HTTP.postJson("/duplicateRemoval/img", data);
  },

  // 分页获取轮播图
  getBannerPageList: function(data) {
    return HTTP.postJson("/banner/page", data);
  },
  // 根据Id获取轮播图
  getBannerById: function(data) {
    return HTTP.postJson("/banner/byId", data);
  },
  // 新增轮播图
  addBanner: function(data) {
    return HTTP.postJson("/banner/add", data);
  },
  // 删除轮播图
  deleteBanner: function(data) {
    return HTTP.postJson("/banner/delete", data);
  },
  // 修改轮播图
  editBanner: function(data) {
    return HTTP.postJson("/banner/editor", data);
  },
  // 更新轮播图状态
  updateBannerStatus: function(data) {
    return HTTP.postJson("/banner/updateStatus", data);
  },
  // 分页获取角色
  getRolePageList: function(data) {
    return HTTP.postJson("/role/page", data);
  },
  // 根据Id获取角色
  getRoleById: function(data) {
    return HTTP.postJson("/role/byId", data);
  },
  // 新增角色
  addRole: function(data) {
    return HTTP.postJson("/role/add", data);
  },
  // 删除角色
  deleteRole: function(data) {
    return HTTP.postJson("/role/delete", data);
  },
  // 修改角色
  editRole: function(data) {
    return HTTP.postJson("/role/editor", data);
  },
  // 更新角色状态
  updateRoleStatus: function(data) {
    return HTTP.postJson("/role/updateStatus", data);
  },
  // 获取全部菜单列表
  getAllMenuList: function(data) {
    return HTTP.postJson("/menu/byAllList", data);
  },
  //腕表批量导入图片
  importBatchPhoto: function(data) {
    return HTTP.postJson("/wristWatch/batchImportImg", data);
  },
  moveBanner: function(data) {
    return HTTP.postJson("/banner/updateSort", data);
  }
};

export default new API();
